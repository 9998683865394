.bubble-cms {
	height: 100vh;
	background: #0582CA;
	width: auto;
}
.bcms-auth-form {
	position: absolute;
	top: 40%;
	left: 51%;
	margin: -100px 0 0 -200px;
	z-index: 1;
	background: #fff;
	width: 350px;
	height: 350px;
	border-radius: 5px 5px 0px 0px;
	box-shadow: 5px 5px 5px rgba(10, 124, 190, .5);
	overflow: hidden;
}

.bcms-auth-form .bcms-auth-particle-1 {
  position: absolute;
  z-index: -1;
  top: 66%;
  left: -102px;
	width: 550px;
}

.bcms-auth-form .bcms-auth-particle-2 {
  position: absolute;
  transform: rotate(45deg);
  z-index: -1;
  top: -120px;
  left: 200px;
	height: 150px;
	width: 150px;
	background: #0473b3;
	border-radius: 25px;
	box-shadow: 1px 1px 1px #ccc;
}

.bcms-auth-header {
	position: absolute;
	top: 30%;
	left: 50%;
	margin: -100px 0 0 -200px;
	width: 400px;
}

.bcms-auth-header h1 {
	font-family: 'Oswald', sans-serif;
	font-size: 19px;
	text-align: center;
	color: white;
}

.bcms-auth-header p {
	font-family: 'Oswald', sans-serif;
	font-size: 17px;
	text-align: center;
	color: white;
}

.bubble-cms input[type=text] {
	font-family: 'Oswald', sans-serif;
    font-size: 16px;
    width: 100%;
    padding: 12px 5px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 0;
    border-bottom: 3px solid #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
}

.bubble-cms input[type=text]:focus {
    border-bottom: 3px solid #0582CA;
}

.bubble-cms input[type=password] {
	font-family: 'Oswald', sans-serif;
	font-size: 16px;
    width: 100%;
    padding: 12px 5px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 0;
    border-bottom: 3px solid #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
}

.bubble-cms input[type=password]:focus {
  border-bottom: 3px solid #0582CA;
}

.bcms-auth-login {
	padding: 30px;
}

.bcms-auth-btn {
	position: absolute;
	font-family: 'Oswald', sans-serif;
	height: 65px;
	width: 65px;
	border-radius: 100%;
	display: inline-block;
	text-decoration: none;
	font-size:30px;
	background: #fff;
	cursor:pointer;
	color: #0582CA;
	outline: none;
	border:3px solid #0473b3;
	left: 41%;
	top: 72%;
}

.bcms-auth-btn:hover {
	background: #0473b3;
	color: white;
}

.bcms-auth-btn:focus {
	background: #fff;
	border: 3px solid #ccc;
	color: #0473b3;
}

.bcms-auth-label {
	font-family: 'Oswald', sans-serif;
	font-size:18px;
	color: #a3a2a2;
}


.bcms-auth-context {
    width: 100%;
    position: absolute;
    top:50vh;
    
}

.bcms-auth- h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
}


.bcms-auth-particles{
    background: #0582CA;  
    background: -webkit-linear-gradient(to left, #8f94fb, #0582CA);  
    width: 100%;
    height:100vh;
    
   
}

.bcms-auth-particles-object{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.bcms-auth-particles-object li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.bcms-auth-particles-object li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.bcms-auth-particles-object li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.bcms-auth-particles-object li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.bcms-auth-particles-object li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.bcms-auth-particles-object li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.bcms-auth-particles-object li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.bcms-auth-particles-object li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.bcms-auth-particles-object li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.bcms-auth-particles-object li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.bcms-auth-particles-object li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(-1200px) rotate(720deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
			transform: translateY(0) rotate(0deg);
        opacity: 0;
        border-radius: 50%;
    }

}