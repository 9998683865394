@import "bootstrap/dist/css/bootstrap.min.css";

#wrapper {
  overflow-x: hidden;
}
#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}
#sidebar-wrapper .list-group {
  width: 15rem;
}
#page-content-wrapper {
  min-width: 100vw;
}
body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}

.btn-bordered {
  border-radius: 0px 25px 25px 0;
}

.btn-inactive:hover {
  background: rgba(211,211,211, 0.4) !important;
}

.paginate-btn-active {
  background: no-repeat linear-gradient(98deg, #D81E5B, #431859 94%) !important;
}
.paginate-btn-active .page-link {
  background: no-repeat linear-gradient(97.79deg, #D81E5B 2.22%, #431859 166.12%) !important;
  border-color: #431859 !important;
}

.paginate-btn-active .page-link:focus {
  box-shadow: 0 0 0 3px rgba(67, 24, 89,.2) !important;
}
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  background: rgba(67, 24, 89,.1);
  color: #431859;
}

.page-link {
  color: black;
}

.btn-nav {
  cursor: pointer;
}

.btn-nav:hover {
  cursor: pointer;
  background: no-repeat linear-gradient(98deg, #D81E5B, #431859 94%);
  color: white;
}

.bl-list-group .list-group-item.active {
  background: no-repeat linear-gradient(98deg, #D81E5B, #431859 94%);
  background-color: transparent;
  box-shadow: 0px 4px 8px -4px rgb(58 53 65 / 42%);
  border-color: #431859;
}

.btn-panel {
  background: no-repeat linear-gradient(98deg, #D81E5B, #431859 94%);
  box-shadow: 0px 4px 8px -4px rgb(58 53 65 / 42%);
}

.btn-bl {
  background: no-repeat linear-gradient(98deg, #D81E5B, #431859 94%);
  box-shadow: 0px 4px 8px -4px rgb(58 53 65 / 42%);
  border-color: #431859;
}
.btn-bl:focus {
  box-shadow: 0 0 0 3px rgba(67, 24, 89,.2) !important;
  border-color: #431859;
}
.btn-bl:hover {
  border-color: #431859;
  box-shadow: 0 0 0 3px rgba(67, 24, 89,.2) !important;
}

.ico-btn {
  color: black;
}

.ico-btn:hover {
  cursor: pointer;
}

.ico-trash:hover {
  color: red;
}

.ico-visible:hover {
  color: #67AAF9;
}

.ico-edit:hover {
  color: green;
}

.outline-btn-bl {
  color: black;
  box-shadow: 0px 4px 8px -4px rgb(58 53 65 / 42%);
  border-color: #431859;
}
.outline-btn-bl:focus {
  box-shadow: 0 0 0 3px rgba(67, 24, 89,.2) !important;
  border-color: #431859;
}
.outline-btn-bl:hover {
  border-color: #431859;
  background: no-repeat linear-gradient(98deg, #D81E5B, #431859 94%);
  box-shadow: 0 0 0 3px rgba(67, 24, 89,.2) !important;
}

/*.row-sm-cms:first-child{
  margin-right: -1px;
}

.row-sm-cms:last-child{
  margin-left: -1px;
}*/

.row-sm {
  --bs-gutter-x: 0.5em;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.color-cms {
  color: #431859;
  stroke: black;
}

.logotype {
  height: 48px;
  width: 48px;
}
@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
 }
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
 }
  body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -15rem;
 }
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  .admintable td {
    font-size: 14px;
  }
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
    border-radius: 10px;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23431859%27/%3e%3c/svg%3e");
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23ffffff%27/%3e%3c/svg%3e");
}

.form-check-input:focus {
  border-color: #431859;
}

.form-check-input:checked {
  background-color: #431859;
  border-color: #431859;
}

.form-check-input:focus {
  box-shadow: 0 0 0 3px rgba(119, 118, 188, .2) !important;
}

.form-control:focus, .form-select:focus {
  box-shadow: 0px 4px 8px -4px rgb(58 53 65 / 42%);
  border-color: #431859;
}

.btn-profile:hover {
  cursor: pointer;
  color: #000;
  background-color: transparent;
}

.license-text p {
  font-family: monospace;
  font-size: 15px;
}

.new-ticket i {
  background: -webkit-linear-gradient(98deg, #D81E5B, #431859 94%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.react-datepicker {
  color: #c7c7c7 !important;
}
.react-datepicker__header {
  background-color: transparent !important;
  background: -webkit-linear-gradient(98deg, #D81E5B, #431859 94%) !important;
  border: none !important;
}
.react-datepicker__day-name {
  color: white !important;
}
.react-datepicker__day {
  color: black !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__day--selected {
  background: -webkit-linear-gradient(98deg, #D81E5B, #431859 94%) !important;
  color: white !important;
}
.react-datepicker__day--disabled {
  color: #c7c7c7 !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: white !important;
}

.react-datepicker__navigation-icon {
  top: 6px !important;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: white !important;
}